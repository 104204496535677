<template>
  <section class="w-full">
    <header-register :showButtonView="(previewButtonIsVisible = false)"
      >{{ $t("sectionUsers.editUser") }}
    </header-register>
    <main class="w-full">
      <ManageUsersEdit />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageUsersEdit from "@/components/users/components/edit/ManageUsersEdit.vue";

export default {
  name: "UsersEdit",
  components: {
    HeaderRegister,
    ManageUsersEdit,
  },
};
</script>
